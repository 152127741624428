import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { find, findIndex, findIndexIndex, findLastIndex } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ManageService } from 'src/app/core/services/manage.service';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { SiteService } from 'src/app/core/services/site.service';

import {
  SlideFormatComponent,
  FormValues as SlideFormatFormValues,
} from '../slide-format/slide-format.component';
import {
  SlideDescriptionDefaultComponent,
  FormValues as SlideDescriptionFormValues,
} from '../slide-description-default/slide-description-default.component';
import {
  SlidePreferencesComponent,
  FormValues as SlidePreerencesFormValues,
} from '../slide-preferences/slide-preferences.component';
import {
  SlideDescriptionIndividualSpotlightComponent,
  FormValues as SlideContentIndividualSpotlightFormValues,
} from '../slide-description-individual-spotlight/slide-description-individual-spotlight.component';
import {
  SlideDescriptionCollageComponent,
  FormValues as SlideContentCollageFormValues,
} from '../slide-description-collage/slide-description-collage.component';
import { Announcement } from '@models/announcement';
import { AnnouncementDto } from '@interfaces/announcement';
import { Image } from '@models/image';
import { SlideStyleType } from '../../../../../../core/enums/slide-style-type';
import {
  SlideDescriptionGifComponent,
  FormValues as SlideContentGifFormValues,
} from '../slide-description-gif/slide-description-gif.component';
import { CreateAnnouncementPayload } from 'src/app/core/interfaces/api';

export interface FormValues {
  format: SlideFormatFormValues;
  description: SlideDescriptionFormValues;
  preferences: SlidePreerencesFormValues;
  contentIndividualSpotlight: SlideContentIndividualSpotlightFormValues;
  contentCollage: SlideContentCollageFormValues;
  contentGif: SlideContentGifFormValues;
}
export interface SlideSteps {
  id: string;
  title: string;
  stepperName: string;
  isActive: boolean; // is the user's current step
  isValid: boolean; // is a valid slide at this time (different announement types will impact steps)
}

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.scss'],
})
export class AddAnnouncementComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public stepIndex = 0;
  public date = new Date();
  public isSubmitting = false;
  public formIsLoading = false;
  public announcementId: number; // if editing
  public announcement: Announcement;
  public previewData: Partial<Announcement> = new Announcement({});
  public image: Partial<Image> = new Image({});
  public form = this.formBuilder.group({
    format: new UntypedFormGroup(SlideFormatComponent.formModel()),
    description: new UntypedFormGroup(
      SlideDescriptionDefaultComponent.formModel(),
    ),
    preferences: new UntypedFormGroup(SlidePreferencesComponent.formModel()),
    contentIndividualSpotlight: new UntypedFormGroup(
      SlideDescriptionIndividualSpotlightComponent.formModel(),
    ),
    contentCollage: new UntypedFormGroup(
      SlideDescriptionCollageComponent.formModel(),
    ),
    contentGif: new UntypedFormGroup(SlideDescriptionGifComponent.formModel()),
  });
  public style = SlideStyleType;

  constructor(
    private route: ActivatedRoute,
    private alert: AlertService,
    public auth: AuthService,
    private formBuilder: UntypedFormBuilder,
    public manage: ManageService,
    private router: Router,
    private siteService: SiteService,
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.announcementId = params.announcementId;

      if (this.announcementId) {
        this.formIsLoading = true;
        this.manage
          .getEntryById(this.announcementId)
          .then((announcement: Announcement) => {
            this.formIsLoading = false;

            if (!announcement) {
              this.announcementNotFound();
              return;
            }

            this.announcement = announcement;
            this.createAnnouncementForm(announcement);
          })
          .catch((error) => {
            this.formIsLoading = false;
            this.announcementNotFound();
            console.error(error);
          });
      }
    });

    this.listenForFormUpdates();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private announcementNotFound() {
    this.alert.error('Could not find corresponding record to edit.');
    this.goBack();
  }

  // FORMS
  /**
   * Update the previewData whenever the form updates
   * so the preview slide is updated in real-time.
   */
  private listenForFormUpdates(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((form: FormValues) => {
        const format = SlideFormatComponent.serialize(form.format);
        const description = SlideDescriptionDefaultComponent.serialize(
          form.description,
        );
        const preference = SlidePreferencesComponent.serialize(
          form.preferences,
        );
        const contentIndividualSpotlight =
          SlideDescriptionIndividualSpotlightComponent.serialize(
            form.contentIndividualSpotlight,
          );
        const contentCollage = SlideDescriptionCollageComponent.serialize(
          form.contentCollage,
        );
        const contentGif = SlideDescriptionGifComponent.serialize(
          form.contentGif,
        );
        this.previewData = new Announcement({
          id: Number(this.announcementId),
          title: description.title,
          content: description.description,
          startShowing: preference.startShowing,
          stopShowing: preference.stopShowing,
          displayDuration: preference.displayDuration,
          styleId: format.styleId,
          siteId: this.siteService.currentSiteId,
          image: description.image,
          imageId: description.image?.id,
          background: format.background,
          category: format.category,
          subcategory: format.subcategory,
          positionImageAboveWeatherBar: format.positionImageAboveWeatherBar,
        });
        if (this.previewData.isIndividualSpotlight) {
          this.previewData.content = contentIndividualSpotlight.description;
          this.previewData.title = contentIndividualSpotlight.title;
          this.previewData.imageId = contentIndividualSpotlight.imageId;
          this.previewData.image = contentIndividualSpotlight.image;
        }
        if (this.previewData.isCollage) {
          const images = [
            contentCollage.image1,
            contentCollage.image2,
            contentCollage.image3,
            contentCollage.image4,
          ];
          this.previewData.title = contentCollage.title;
          this.previewData.content = contentCollage.description;
          this.previewData.numberOfImages = contentCollage.numberOfImages;
          this.previewData.secondaryImages = images.filter((image) => !!image);
        }

        if (this.previewData.isGif) {
          this.previewData.title = contentGif.title;
          this.previewData.content = contentGif.description;
          this.previewData.secondaryImages = [contentGif.image];
        }
        this.previewData.clearUnusedSlideData();
      });
  }

  private createAnnouncementForm(announcement: Announcement): void {
    this.form.patchValue({
      format: SlideFormatComponent.deserializeToForm(announcement),
      description:
        SlideDescriptionDefaultComponent.deserializeToForm(announcement),
      preferences: SlidePreferencesComponent.deserializeToForm(announcement),
      contentIndividualSpotlight:
        SlideDescriptionIndividualSpotlightComponent.deserializeToForm(
          announcement,
        ),
      contentCollage:
        SlideDescriptionCollageComponent.deserializeToForm(announcement),
      contentGif: SlideDescriptionGifComponent.deserializeToForm(announcement),
    });
  }

  // STATE VARIABLES
  /**
   * Returns the current step in workflow
   */
  get activeStep(): SlideSteps {
    return find(this.steps, { isActive: true });
  }

  get isFirstStep(): boolean {
    return this.activeStepIndex === this.firstStepIndex;
  }

  get isLastStep(): boolean {
    return this.activeStepIndex === this.lastStepIndex;
  }

  get firstStepIndex(): number {
    return findIndexIndex(this.steps, { isValid: true });
  }

  get lastStepIndex(): number {
    return findLastIndex(this.steps, { isValid: true });
  }

  /**
   * Returns the current step index in workflow
   */
  get activeStepIndex(): number {
    return findIndex(this.steps, { isActive: true });
  }

  /**
   * Returns true if the form associated with the current step is valid
   */
  get currentStepIsValid(): boolean {
    let activeFormControlSection = this.activeStep.id;
    const slideFormat: SlideFormatFormValues =
      this.form.controls['format'].value;

    if (activeFormControlSection === 'description') {
      if (slideFormat.styleId === 13) {
        // collage style
        activeFormControlSection = 'contentCollage';
      } else if (slideFormat.styleId === 1) {
        // individual spotlight
        activeFormControlSection = 'contentIndividualSpotlight';
      } else if (slideFormat.styleId === 30) {
        // gif
        activeFormControlSection = 'contentGif';
      }
    }

    const activeSection = this.form.controls[activeFormControlSection];

    // Special handling for preferences step when publishNow is true
    if (activeFormControlSection === 'preferences') {
      const preferencesForm = activeSection as UntypedFormGroup;
      if (preferencesForm.value.publishNow) {
        // When publishing now, only validate stopShowing fields and duration
        return (
          preferencesForm.get('durationInSecs').valid &&
          (preferencesForm.get('stopShowing').valid ||
            preferencesForm.value.publishForever) &&
          (preferencesForm.get('stopShowingTime').valid ||
            preferencesForm.value.publishForever)
        );
      }
    }

    return activeSection.valid;
  }

  /**
   * Get the steps for the current slide workflow, based on the slide type
   */
  get steps(): SlideSteps[] {
    const steps = [
      {
        id: 'format',
        title: 'Choose the Slide Format',
        stepperName: 'Format',
        isActive: true,
        isValid: true,
      },
      {
        id: 'description',
        title: 'Write the Slide Content',
        stepperName: 'Description',
        isActive: false,
        isValid: true,
      },
      {
        id: 'preferences',
        title: 'Set the Display Preferences',
        stepperName: 'Finish',
        isActive: false,
        isValid: true,
      },
    ];

    return steps.map((step: SlideSteps, index: number) => {
      step.isActive = index === this.stepIndex;
      return step;
    });
  }

  // NAVIGATION
  stepChanged(event: number) {
    this.stepIndex = event;
  }
  /**
   * Move forwards one step
   */
  public nextStep(): void {
    const currentIndex = this.activeStepIndex;
    const stepsLength = this.steps.length;
    if (currentIndex < stepsLength - 1 && this.currentStepIsValid) {
      this.stepIndex++;
    }
  }

  /**
   * Move backwards one step
   */
  public backStep(): void {
    const currentIndex = this.activeStepIndex;

    if (currentIndex > 0) {
      this.stepIndex--;
    }

    if (!this.activeStep.isValid) {
      this.backStep();
    }
  }

  public progressForm(): void {
    // Ensure active form is valid
    if (!this.currentStepIsValid) {
      return;
    }

    this.nextStep();
  }

  public saveSlide(): void {
    this.isSubmitting = true;
    const siteId = this.siteService.currentSiteId;
    // Create the data object for CreateAnnouncementPayload
    const data: CreateAnnouncementPayload = {
      title: this.previewData.title,
      content: this.previewData.content,
      startShowing: this.previewData.startShowing.toISOString(),
      stopShowing: this.previewData.stopShowing.toISOString(),
      location: this.previewData.location,
      displayDuration: this.previewData.displayDuration,
      eventStart: null,
      eventEnd: null,
      isSpecialEvent: this.previewData.isSpecialEvent,
      videoUrl: this.previewData.videoUrl,
      eventType: this.previewData.eventType as any,
      allDay: this.previewData.allDay,
      siteId: siteId,
      styleId: this.previewData.styleId,
      imageId: this.previewData.imageId,
      backgroundId:
        this.previewData.background.id || this.previewData.backgroundId,
      roomId: null,
      categoryId: this.previewData.category?.id,
      subcategoryId: this.previewData.subcategory?.id,
      showOnPrintCalendar: this.previewData.showOnPrintCalendar,
      showOnDigitalSignage: this.previewData.showOnDigitalSignage,
      secondaryImages: this.previewData.secondaryImages?.map(
        (image) => image.id,
      ),
      eventTags: this.previewData.eventTags
        ? this.previewData.eventTags.map((e) => e.id)
        : [],
      recurrenceId: null,
      positionImageAboveWeatherBar:
        this.previewData.positionImageAboveWeatherBar,
    };
    if (this.announcementId) {
      this.updateAnnouncement(data, this.announcementId);
    } else {
      this.createAnnouncement(data);
    }
  }

  public goBack() {
    this.router.navigate([`/manage/content/${this.siteService.currentSiteId}`]);
  }

  // API EVENTS
  private updateAnnouncement(
    announcement: CreateAnnouncementPayload,
    id: number,
  ) {
    this.manage
      .updateAnnouncement(announcement, id)
      .then((announce: AnnouncementDto) => {
        this.isSubmitting = false;
        this.alert.success(`${announce.title} updated.`);
        this.goBack();
      })
      .catch((error) => {
        this.isSubmitting = false;
        this.alert.warning('Unable to save. Please try again.');
      });
  }

  private createAnnouncement(announcement: CreateAnnouncementPayload) {
    this.manage
      .saveAnnouncement(announcement)
      .then((announce: AnnouncementDto) => {
        this.isSubmitting = false;
        this.alert.success(`${announce.title} saved.`);
        this.goBack();
      })
      .catch((error) => {
        this.isSubmitting = false;
        this.alert.warning('Unable to save. Please try again.');
      });
  }
}
